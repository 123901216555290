<template>
  <div class="equityBox">
    <div class="title1">三项有偿服务功能权益</div>
    <div class="buleIcon">文本短信/视频短信/5G消息,精准群发营销解决方案</div>
    <div class="contentBox">
      <div class="contentchildBox" v-for="(item, index) in list" :key="index">
        <img :src="item.img" alt="" />
        <div class="childboxTitle">{{ item.title }}</div>
        <div class="childContent">
          {{ item.content }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          img: require("../assets/image5.png"),
          title: "短信群发营销",
          content:
            "文本短信,具备快速触达手机用户的能力;验证码、通知、会员推广、会员营销;国内短信三网合一,5S到达,99%+触达率，简单稳定，安全易用。",
        },
        {
          img: require("../assets/image4.png"),
          title: "视频短信营销",
          content:
            "基于移动网络和互联网通信技术，集成视频、语音、 图片、文字、短链、二维码等信息通讯能力,支持1.8M信息容量、30秒视频、5帧高清图,采用移动数据网络传输方式发送后自行下载,存储在用户手机收件箱中。",
        },
        {
          img: require("../assets/image3.png"),
          title: "5G消息营销",
          content:
            "即发即现,直接展示,减少拦截,全网三网覆盖,支持视频、语音、高清图片、文字、图文动参内容、二维码、H5链接跳转、个性接入,实时访问统计。",
        },
      ],
    }
  },
}
</script>

<style lang="less" scoped>
.equityBox {
  width: 1200px;
  margin: 0 auto;
  margin-top: 90px;
  .title1 {
    height: 36px;
    font-size: 44px;
    color: #333333;
    text-align: center;
  }
  .buleIcon {
    color: #4a4a4a;
    margin-top: 29px;
    text-align: center;
  }
  .contentBox {
    margin-top: 50px;
    display: flex;
    .contentchildBox {
      width: 390px;
      height: 370px;
      background: #ffffff;
      box-shadow: 0px 2px 8px 1px rgba(185, 185, 185, 0.31);
      border-radius: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-left: 10px;
      padding-top: 40px;
      .contentchildBox img {
        display: block;
        width: 54px;
        height: 64px;
      }
      .childboxTitle {
        font-size: 24px;
        color: #333333;
        margin-top: 50px;
      }
      .childContent {
        padding: 0 40px 0 45px;
        text-align: center;
        margin-top: 28px;
      }
    }
  }
}
</style>
