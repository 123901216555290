<template>
  <component :is="componentName" />
</template>

<script>
import HomeMobile from "./HomeMobile.vue"
import HomePc from "./HomePc.vue"

// @ is an alias to /src
export default {
  name: "Home",
  data() {
    return {
      componentName: "",
    }
  },
  mounted() {
    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      this.componentName = "HomeMobile"
    } else {
      this.componentName = "HomePc"
    }
  },
  components: { HomeMobile, HomePc },
}
</script>
<style lang="less" scoped></style>
