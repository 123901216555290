<template>
  <div class="topmiddle">
    <div class="leftBox">
      <div class="title">什么是企业官媒</div>
      <div style="font-size: 16px">搭建企业官方新媒体平台，</div>
      <div style="margin-bottom: 90px; font-size: 16px">
        实现企业全网新媒体免费营销一站式获客解决方案！
      </div>
      <div
        style=""
        v-for="(item, index) in List"
        :key="index"
        class="leftbottomBox"
      >
        <img :src="item.img" style="width: 60px; height: 60px" />
        <div style="margin-left: 20px; font-size: 16px">
          <div>{{ item.concent }}</div>
          <div>{{ item.concent2 }}</div>
        </div>
      </div>
    </div>
    <div class="rightBox">
      <div><img src="../assets/IMAGE38.png" /></div>
      <div><img src="../assets/IMAGE39.png" alt="" /></div>
      <div><img src="../assets/IMAGE40.png" alt="" /></div>
      <div><img src="../assets/IMAGE41.png" alt="" /></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      List: [
        {
          img: require("../assets/IMAGE34.png"),
          concent: "企业官媒v唯一标识,增强品牌权威性",
          concent2: "提升知名度。",
        },
        {
          img: require("../assets/IMAGE35.png"),
          concent: "建立用户信任感，官媒昵称唯一",
          concent2: "认证企业官媒不会出现重名现象。",
        },
        {
          img: require("../assets/IMAGE36.png"),
          concent: "连接用户，搭建高效连接用户的桥梁",
          concent2: "拥有经营用户关系以及打造私域流量阵地。",
        },
        {
          img: require("../assets/IMAGE37.png"),
          concent: "发动主推生意增长的引擎，",
          concent2: "实现精准营销一站式获客解决方案。",
        },
      ],
    }
  },
}
</script>

<style lang="less" scoped>
.topmiddle {
  text-align: left;
  display: flex;
  justify-content: space-around;
  width: 1200px;
  margin: 90px auto;
  .leftBox {
    .title {
      font-size: 44px;
      color: #333333;
      margin-bottom: 20px;
    }
    .leftbottomBox {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
    }
  }
  .rightBox {
    display: flex;
    flex-wrap: wrap;
    width: 626px;
    img {
      width: 303px;
      height: 293px;
      border-radius: 4px;
      margin-left: 10px;
    }
  }
}
</style>
