<template>
  <div class="bottom">
    <div class="title1">十二项权益</div>
    <div class="title2">12项免费权益赋能免费推广,赋能创业创新</div>
    <div class="bottomBox">
      <div v-for="item in List" :key="item.index" style="margin-left: 5px">
        <div class="box">
          <div style="font-size: 24px; text-align: left; font-weight: bold">
            {{ item.name }}
          </div>
          <div style="font-size: 16px; text-align: left; margin-top: 40px">
            {{ item.content }}
          </div>
        </div>
        <!-- <div class="box2"></div> -->
        <!-- </transition> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      List: [
        {
          name: "微销通",
          content:
            "企业版分销系统,企业官媒主账号可绑定若干微信子账号,均可实现分销,分佣,客户数据,分销商级别等,一键管理。",
        },
        {
          name: "加盟商",
          content:
            "企业官媒认证持有人生成二维码,用户扫码成为加盟商,拥有加盟商身份即可持续发展加盟商,获得高额佣金奖励,还可享受免费推广宣传服务以及扶持政策",
        },
        {
          name: "全网资讯",
          content:
            "基于企业官媒SAAS系统,企业营销开放平台,一键发布,精准直达,实现软文营销全网覆盖,同步至主流媒体平台。",
        },
        {
          name: "红包推广",
          content:
            "商户原创焦点营销软文/短视频等,充值即可开启红包推广,用户充分发挥红包裂变思维,优惠促销,品牌营销宣传推广,低门槛,低成本,大众创业,万众创新,人人互动,参与共赢,真正的品牌口碑相传。",
        },
        {
          name: "商机发布",
          content:
            "通过企业官媒平台,一键发布,三步完成招商加盟,深度挖掘追踪意向代理,合作伙伴与加盟商。",
        },
        {
          name: "信息流广告",
          content:
            "基于品推资讯 APP 客户端,PC 版,H5版AI智能推送,通过海量媒体资源实现信息采集、深度数据挖掘和用户行为分析,为用户智能推荐个性化信息,实现精准获客一站式解决方案.",
        },
        {
          name: "视频同步助手",
          content:
            "同步助手是一款海量短视频分发平台,一键分发抖音,头条,西瓜,品推短视频,腾讯平台,可实现后台同步管理/分发,查看用户转发量,点赞量以及评论等等,同步助手资源丰富、功能齐全、安全轻便、同时支持多平台。",
        },
        {
          name: "新媒体商学院",
          content:
            "企业官媒商学院是以企业战略和人才开发为核心,根据业务需要实现业务流程以及业务知识的全方位培训,以构筑企业全员培训体系为基础,通过企业文化的导入和企业学习习惯的培育,形成企业知识管理、业务人才加工、市场竞争的智力窗口,最终成为实现企业战略规划的有力武器。",
        },
        {
          name: "官媒资质",
          content: "官方资质展示,增强品牌权威信, 提升知名度",
        },
        {
          name: "其他资质展示",
          content:
            "增加商标、版权、电商证、品牌证等资质证书展示,增强品牌权威信",
        },
        {
          name: "官网展示",
          content: "配置企业官网,强化主页主动运营能力。",
        },
        {
          name: "产品服务展示",
          content: "配置产品服务,方便客户更快更便捷的了解公司",
        },
      ],
      hoverIndex: 0,
    }
  },
  methods: {
    changeHoverIndex(index) {
      this.List[index].show = !this.List[index].show
    },
    leaveHoverIndex(index) {
      this.List[index].show = !this.List[index].show
    },
  },
}
</script>

<style lang="less" scoped>
.bottom {
  width: 1200px;
  margin: 0 auto;
  margin-top: 90px;
  .title1 {
    height: 36px;
    font-size: 44px;
    color: #333333;
    text-align: center;
  }
  .title2 {
    font-size: 16px;
    color: #4a4a4a;
    margin-top: 40px;
    text-align: center;
  }
  .bottomBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    margin-top: 70px;
    .box {
      background: #ffffff;
      box-shadow: 0px 2px 8px 1px rgba(185, 185, 185, 0.31);
      color: #333333;
      width: 390px;
      height: 285px;
      margin: 0 auto;
      padding: 30px 30px;
      margin-top: 10px;
      border-radius: 10px;
      background-image: url("../assets/yinhao2.png");
      background-repeat: no-repeat;
      background-position: 300px 30px;
      background-size: 60px 46px;
    }
    .box:hover {
      background: #0a49ff;
      border-radius: 10px;
      color: #ffffff;
      background-image: url("../assets/yinhao1.png");
      background-repeat: no-repeat;
      background-position: 300px 30px;
      background-size: 60px 46px;
    }
  }
}
.slide-fade-enter-active {
  transition: 0.3s ease;
}
.slide-fade-leave-active {
  transition: 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
