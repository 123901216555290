<template>
  <div class="topmiddle">
    <div class="middletitle">
      <div>共同富裕创业平台招商加盟</div>
      <div class="buleIcon">Common Wealth Entrepreneurship Platform</div>
    </div>
    <div
      style="display: flex; margin-top: 120px; justify-content: space-around"
    >
      <div>
        <div
          class="rightBox"
          v-for="(item, index) in stepList"
          :key="index"
          @mouseenter="changeHoverIndex(index)"
          @mouseleave="leaveHoverIndex(index)"
        >
          <div class="Split"></div>
          <div :class="item.color === false ? 'title' : 'selectTitle'">
            {{ item.title }}
          </div>
          <div v-if="item.color" style="font-size: 16px">
            <div>{{ item.content1 }}</div>
            <div>{{ item.content2 }}</div>
            <div>{{ item.content3 }}</div>
          </div>
        </div>
      </div>
      <div v-if="index === 0">
        <img src="../assets/IMAGE42.png" style="width: 311px; height: 613px" />
      </div>
      <div v-if="index === 1">
        <img src="../assets/IMAGE43.png" style="width: 311px; height: 613px" />
      </div>
      <div v-if="index === 2">
        <img src="../assets/IMAGE44.png" style="width: 311px; height: 613px" />
      </div>
      <div v-if="index === 3">
        <img src="../assets/IMAGE45.png" style="width: 311px; height: 613px" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stepList: [
        {
          title: "下载品推资讯",
          content1: "扫码下载",
          content2: "品推资讯APP",
          color: true,
        },
        {
          title: "登录品推资讯",
          content1: "登录",
          content2: "企业官媒认证账号",
          color: false,
        },
        {
          title: "设置加盟费",
          content1: "点击加盟商",
          content2: "设置加盟费金额",
          content3: "设置分佣比例",
          color: false,
        },
        {
          title: "分享意向加盟商",
          content1: "企业官媒品牌名称",
          content2: "分享给意向加盟商",
          content3: "实现盈利",
          color: false,
        },
      ],
      index: 0,
    }
  },
  methods: {
    changeHoverIndex(index) {
      this.stepList[index].color = !this.stepList[index].color
      this.index = index
      this.stepList[0].color = !this.stepList[0].color
    },
    leaveHoverIndex(index) {
      this.stepList[index].color = !this.stepList[index].color
      this.stepList[0].color = !this.stepList[0].color
      this.index = 0
    },
  },
}
</script>

<style lang="less" scoped>
.topmiddle {
  margin-top: 70px;
  width: 1200px;
  margin: 0 auto;
  .middletitle {
    text-align: center;
    height: 36px;
    font-size: 36px;
    margin-top: 80px;
    color: #333333;
    .buleIcon {
      margin: 0 auto;
      margin-top: 20px;
      font-size: 16px;
    }
  }
  .rightBox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 160px;
    .Split {
      width: 390px;
      height: 1px;
      background: #d7d7d7;
    }
    .title {
      font-weight: bold;
      font-size: 19px;
      margin-bottom: 20px;
      margin-top: 30px;
    }
    .selectTitle {
      color: #0a49ff;
      font-size: 19px;
      margin-bottom: 20px;
      margin-top: 30px;
      font-weight: bold;
    }
  }
}
</style>
