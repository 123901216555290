<template>
  <div class="middle">
    <div class="topmiddle">
      <div class="middletitle">
        <div>互联网+新媒体4.0时代发展历程</div>
        <div class="title2">互联网创业平台发展趋势</div>
        <!-- <div class="buleIcon"></div> -->
      </div>
    </div>
    <div
      style="
        display: flex;
        justify-content: center;
        width: 1200px;
        margin: 0 auto;
      "
    >
      <div
        class="businessBox"
        v-for="(item, index) in busiessList"
        :key="index"
      >
        <div class="businessTitle">{{ item.businessTitle }}</div>
        <div class="businessConcent">
          {{ item.businessConcent }}
          <div>{{ item.concent }}</div>
          <div>{{ item.duanluo }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hoverIndex: 1,
      busiessList: [
        {
          businessTitle: "互联网1.0时代",
          businessConcent:
            "Internet(互联网)进入中国市场真正变现是从面向B端用户开放的时候,那么面向B端用户最经典的变现模式是什么呢,根据艾瑞网官方数据报道,中国的中小企业高达5000万家,那么搭建企业网站的模式就成为了面向B端用户变现最经典的模式,这就是所谓的互联网1.0时代.",
        },
        {
          businessTitle: "互联网2.0时代",
          businessConcent:
            "根据科学技术的不断发展,建设网站仅仅是给予PC端用户的模式,移动互联网的到来彻底打破了多年来搭建网站的变现模式,从PC端的经典建站模式直接搬到了移动端,那就是APP应用程序时代,同样的网站展现模式,改变了用户的使用习惯,以及生活习惯,这就是所谓的互联网2.0时代。",
        },
        {
          businessTitle: "互联网3.0时代",
          businessConcent:
            "小程序是一种不用下载就能使用的应用,也是一项创新,经过多年的发展,已经构造了新的轻应用小程序开发环境和开发者生态,同样的移动端网站展现模式,变得更加便捷,使用起来更加流畅,开发成本更低,这就是所谓的互联网3.0时代。",
          concent:
            "总结:互联网从1.0时代演变至3.0时代,它们的共同点均基于搭建网站用于展现的经典模式,(特点:单一展现模式，被动等客上门)。",
        },
        {
          businessTitle: "互联网4.0时代",
          businessConcent:
            "从互联网1.0时代演变至程序应用2.0时代APP,升级为3.0时代的轻应用小程序.",
          duanluo:
            "在互联网3.0时代基础上演变成了新媒体(自媒体)，人人均是自媒体，商户主体也可是自媒体，企业单位也可是自媒体，每个品牌/商标均可是自媒体,这就是所谓的互联网新媒体4.0时代",
          concent: "互联网+新媒体=内容+流量(粉丝经济时代)",
        },
      ],
    }
  },
  methods: {
    changeHoverIndex(hoverIndex) {
      console.log(hoverIndex, "hoverIndex")
      this.hoverIndex = hoverIndex
    },
  },
}
</script>

<style lang="less" scoped>
.middle {
  background: #ffffff;
  height: 100%;
  .topmiddle {
    margin-bottom: 80px;
    .middletitle {
      text-align: center;
      height: 36px;
      font-size: 44px;
      margin-top: 80px;
      color: #333333;
      .buleIcon {
        width: 60px;
        height: 11px;
        background: #0a49ff;
        border-radius: 6px;
        margin: 0 auto;
        // margin-top: 15px;
      }
      .title2 {
        font-size: 16px;
        color: #666666;
        margin-top: 20px;
      }
    }
  }
  .businessBox {
    width: 290px;
    height: 455px;
    border-radius: 10px;
    margin-left: 10px;
    margin-top: 50px;
    .businessTitle {
      font-size: 24px;
      font-weight: bold;
      color: #ffffff;
      padding: 37px 0 24px 20px;
      text-align: left;
    }
    .businessConcent {
      width: 275px;
      height: 372px;
      background: #ffffff;
      border-radius: 10px;
      padding: 30px 25px 0 20px;
      color: #4a4a4a;
      text-align: left;
      font-size: 16px;
    }
  }
  .businessBox:nth-child(1) {
    background-image: url("../assets/beijing2.png");
    background-size: 290px 455px;
  }
  .businessBox:nth-child(2) {
    background-image: url("../assets/beijing3.png");
    background-size: 290px 455px;
  }
  .businessBox:nth-child(3) {
    background-image: url("../assets/beijing4.png");
    background-size: 290px 455px;
  }
  .businessBox:nth-child(4) {
    background-image: url("../assets/beijing5.png");
    background-size: 290px 455px;
  }
}
</style>
